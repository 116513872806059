import request from '@/utils/request'

// 获取新的验证码id
export function newCaptcha() {
  return request({
    url: `/captcha/new`,
    method: 'get'
  })
}

// 根据验证码id刷新验证码
export function reloadCaptcha(id) {
  return request({
    url: `/captcha/${id}/reload`,
    method: 'get'
  })
}
